import React from "react";
import styles from "./search.module.scss";
import { useTranslation } from "react-i18next";

const SearchComponent=({className})=>{

  const { t } = useTranslation();

  return (
  <div className={styles.search}>
    {/* <div className={styles.search__icon}></div>
    <input className={className ? styles[className] : ""} placeholder={t("search")}/> */}
  </div>
  );
};

export const Search = SearchComponent;
