import React from "react";

import styles from "./popup.module.scss";
import { useTranslation } from "react-i18next";

export const NoisyPopupComponent = ({ name, id, setNoisyAudio, markNoisy }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.popup__bg}>
      <div className={styles.popup}>
        <div className={styles.popup__title}>{t("attention")}</div>
        <p className={styles.popup__text}>
          {`${t("are_you_sure")} ${name} ${t("for_update")}`}
        </p>
        <p className={styles.popup__text}>{`${t("upon_confirmation")}`}</p>
        <div className={styles.popup__buttons}>
          <button
            onClick={() => setNoisyAudio(null)}
            className={`${styles.popup__button} ${styles.popup__button_cancel}`}
          >
            {t("cancel")}
          </button>
          <button
            className={`${styles.popup__button} ${styles.popup__button_send}`}
            onClick={() => markNoisy()}
          >
            {t("send")}
          </button>
        </div>
      </div>
    </div>
  );
};

export const NoisyPopup = NoisyPopupComponent;
