import React from "react";
import { Link } from "react-router-dom";

import {Player} from "../../components";

import styles from "./card.module.scss";

export const CardRow = ({ children, className, name, column, id, number }) => {
  return (
    <div
      className={`${styles.card__row} ${className ? className : ""} ${
        column ? styles.card__column : ""
      }`}
    >
      {name === "player" ? (
        <div className={styles.card__left}>
          <div
            className = {"player_flex_wrapper"}
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            <Player type="min" id={id} />
            <div className={"player_card_id"}>ID {id} {number}</div>
          </div>
        </div>
      ) : (
        <div className={styles.card__left}>{name}</div>
      )}
      <div className={styles.card__right}>{children}</div>
    </div>
  );
};

export const Card = ({ children, className, name, id, href }) => {
  return (
    <div className={`${styles.card} ${className ? className : ""}`}>
      {href ? (
        <Link to={href} className={styles.card__data}>
          {children}
        </Link>
      ) : (
        <div className={styles.card__data}>{children}</div>
      )}
    </div>
  );
};
