import React, { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { useTranslation } from "react-i18next";

import { SelectedBtn } from "../../components";
import styles from "./sortAdaptive.module.scss";

export const SortAdaptive = ({ sortClickHandler,item,filterStr,listChecked }) => {
  const { t } = useTranslation();

  const [isSortMenu, setIsSortMenu] = useState(false);
  const [colorIcon, setColorIcon] = useState("#D9CFD9");

  const activateSortMenu = () => {
    setIsSortMenu(true);
    setColorIcon("#3F0E40");
  };

  const deactivateSortMenu = () => {
    setIsSortMenu(false);
    setColorIcon("#D9CFD9");
  };



  return (
    <>
      <div className={styles.sort_adaptive}>
        <OutsideClickHandler
          onOutsideClick={() => {
            if (setIsSortMenu) {
              deactivateSortMenu()
            }
          }}
        >
          <div className={styles.sort_icon} onClick={() => {
            if (isSortMenu === false) {
              activateSortMenu()
            } else {
              deactivateSortMenu()
            }
            }}>
            <div>
              <svg
                width="20"
                height="18"
                viewBox="0 0 20 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16 18L12 14H15V4H12L16 0L20 4H17V14H20L16 18ZM0 16V14H10V16H0ZM0 10V8H7V10H0ZM0 4V2H4V4H0Z"
                  fill={colorIcon}
                />
              </svg>
            </div>
            <div
              className={`${styles.sort_title} ${
                isSortMenu ? styles.active : ""
              }`}
            >
              {t("sort")}
            </div>
          </div>
        </OutsideClickHandler>
        <div>
          <SelectedBtn
            className={"sort-adaptive"}
            listChecked={listChecked}
            item={item}
            filterStr={filterStr}
          />
        </div>
        <div
          className={`${styles.sort_menu} ${
            isSortMenu ? styles.activated_sort_menu : ""
          }`}
        >
          <div onClick={() =>sortClickHandler ? sortClickHandler("identifier"):null}>{t("name")}</div>
          <div onClick={() =>sortClickHandler ?  sortClickHandler("date"):null}>{t("date")}</div>
          <div onClick={() =>sortClickHandler ?  sortClickHandler("age"):null}>{t("age")}</div>
          <div onClick={() =>sortClickHandler ?  sortClickHandler("gender"):null}>{t("gender")}</div>
        </div>
      </div>
      {isSortMenu && <div className={styles.bg}></div>}
    </>
  );
};
