import React, { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";

import styles from "./select.module.scss";
import { useTranslation } from "react-i18next";

const SelectComponent = ({ choices, value, setValue, align }) => {
  const { t } = useTranslation();
  const [showChoices, setShowChoices] = useState(false);
  const notValue = { text: "Не выбрано", status: "not_selected" };

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        if (showChoices) setShowChoices(false);
      }}
    >
      <div
        onClick={() => setShowChoices(!showChoices)}
        className={`${styles.select} ${
          align === "center" ? styles.center : ""
        }`}
      >
        <div
          className={`${styles.select__preview} ${
            styles[value ? value.status : notValue.status]
          }`}
        >
          <div className={`${styles.select__preview_text} ${value?styles[value.status]:""}`}>
            {value ? t(value.text) : notValue.text}
          </div>
        </div>
        <div
          onClick={() => setShowChoices(!showChoices)} 
          className={`${styles.select__choices} ${
            !showChoices ? styles.hidden : ""
          }`}
        >
          {choices.map((choice) => (
            <div
              key={choice.status}
              className={`${styles.select__item} ${
                choice.status ? styles[choice.status] : ""
              }`}
              onClick={() => {
                setValue(choice);
                setShowChoices(false);
              }}
            >
              {t(choice.text)}
            </div>
          ))}
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export const Select = SelectComponent;
