import {React, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import styles from "./itemNavButtons.module.scss";

const ItemNavButtonsComponent = ({
  prev,
  next,
  setId,
  type,
  filterStatus,
}) => {
  const { t } = useTranslation();
  const [filterStr, setFilterStr] = useState("");

  useEffect(() => {
    const newFilterStr =
      filterStatus && typeof filterStatus === "string"
        ? `?status=${filterStatus}`
        : filterStatus && typeof filterStatus === "object"
        ? `?status=${filterStatus[0]}&status=${filterStatus[1]}`
        : "";
        setFilterStr(newFilterStr)
  }, [filterStatus]);

  return (
    <div className={styles.buttons}>
      {next && (
        <Link
          to={`/${type}/${next}${filterStr}`}
          className={styles.buttons__button}
          onClick={() => {
            if (setId) {
              setId(next); 
            }
          }}
        ></Link>
      )}
      {prev && (
        <Link
          to={`/${type}/${prev}${filterStr}`}
          className={styles.buttons__button}
          onClick={() => {
            if (setId) {
              setId(prev);
            }
          }}
        ></Link>
      )}
    </div>
  );
};

export const ItemNavButtons = ItemNavButtonsComponent;
