export const kHz = ["0", "2,5", "5,0", "7,5", "10,0", "12,5", "15,0", "17,5"];

export const dB = [
  "-80",
  "-70",
  "-60",
  "-50",
  "-40",
  "-30",
  "-20",
  "-10",
  "+0",
];

export const audioData = {
  framerate: "44100",
  treble: "yes",
  filters: "yes",
  bg_noize: "no",
  sn_ratio: "yes",
};
