import React, { useState, useEffect } from "react";

import { formatNumber } from "../../utils";
import styles from "./player.module.scss";

export const SpectrogramPlayer = ({
  audio,
  progress,
  setProgress,
  audioElement,
  setAudioElement,
  duration,
  setDuration,
}) => {
  const [play, setPlay] = useState(false);
  const [currentTime, setCurrentTime] = useState("0.00");

  useEffect(() => {
    const audioEl = new Audio(audio);
    setAudioElement(audioEl);
  }, [audio]);

  useEffect(() => {
    if (audioElement) {
      audioElement.addEventListener("play", playHandler);
      audioElement.addEventListener("pause", pauseHandler);
      audioElement.addEventListener("loadedmetadata", loadHandler);
      audioElement.addEventListener("timeupdate", progressHandler);

      return () => {
        audioElement.pause();

        audioElement.removeEventListener("play", playHandler);
        audioElement.removeEventListener("pause", pauseHandler);
        audioElement.removeEventListener("loadedmetadata", loadHandler);
        audioElement.removeEventListener("timeupdate", progressHandler);
      };
    }
  }, [audioElement]);

  useEffect(() => {
    if (!duration || play) return;

    const newProgress = audioElement.duration * progress;
    audioElement.currentTime = newProgress;
    setCurrentTime(newProgress.toFixed(2));
  }, [progress, duration]);

  const clickPlay = () => {
    if (!play) {
      audioElement.play();
    } else {
      audioElement.pause();
    }
  };

  const clickStop = () => {
    audioElement.pause();
    audioElement.currentTime = 0;
  };

  const clickRepeat = () => {
    clickStop();
    audioElement.play();
  };

  const playHandler = () => {
    setPlay(true);
  };

  const pauseHandler = () => {
    setPlay(false);
  };

  const loadHandler = () => {
    setDuration(formatNumber(audioElement.duration, 2));
  };

  const progressHandler = () => {
    setCurrentTime(audioElement.currentTime.toFixed(2));
    setProgress(audioElement.currentTime / audioElement.duration);
  };

  return (
    <div className={styles.player}>
      <div className={styles.player__container}>
        <div
          className={`${styles.player__btn} ${
            play ? styles.pause : styles.play
          }`}
          onClick={clickPlay}
        />
        <div
          className={`${styles.player__btn} ${styles.stop}`}
          onClick={clickStop}
        />
        <div
          className={`${styles.player__btn} ${styles.repeat}`}
          onClick={clickRepeat}
        />

        {duration && (
          <div className={styles.player__progress}>
            <div className={styles.player__progress_current}>
              {`${currentTime}`}
            </div>
            <div className={styles.player__progress_duration}>
              {`${duration}`}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
