import React from "react";
import { useTranslation } from "react-i18next";

import { useAuth } from "../../utils";
import styles from "./logout.module.scss";

const LogoutButtonComponent = ({ className }) => {
  const { t } = useTranslation();
  const { logout } = useAuth();

  return (
    <div 
    className={styles.logout_button_wrapper}>
      <div className={styles.logout_icon_wrapper}>
        <div onClick={() => logout()} className={styles.logout_icon}></div>
      </div>
      <div onClick={() => logout()}
        className={`${styles.logout} ${className ? className : ""}`}
      >
        {t("sign_out")}
      </div>
    </div>

  );
};

export const LogoutButton = LogoutButtonComponent;
