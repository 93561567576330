import React, { useState, useEffect, useCallback, useMemo } from "react";

import { formatNumber } from "../../utils";
import styles from "./range.module.scss";

export const SpectrogramRange = ({
  width,
  rangeItem,
  setRanges,
  updateEpisodes,
  ranges,
  container,
  imagePosition,
}) => {
  const [minPosition, setMinPosition] = useState(0);
  const [maxPosition, setMaxPosition] = useState(100);
  const [thumb, setThumb] = useState({
    isDragging: false,
    current: null,
    start: 0,
  });

  useEffect(() => {
    if (rangeItem.start) {
      let min = 0;
      let max = 100;

      const newRanges = [...ranges];

      newRanges.forEach((range) => {
        if (range.end < rangeItem.start && range.end > min) {
          min = range.end;
        }
        if (range.start > rangeItem.end && range.start < max) {
          max = range.start;
        }
      });
      if (min > 0) setMinPosition(min + 0.4);
      if (max < 100) setMaxPosition(max - 0.4);
    }
  }, [ranges]);

  const mouseDownHandler = useCallback((clientX, current) => {
    setThumb((state) => ({
      ...state,
      isDragging: true,
      current,
      start: clientX,
    }));
  }, []);

  useEffect(() => {
    if (thumb.isDragging) {
      window.addEventListener("mousemove", mouseMoveHandler);
      window.addEventListener("mouseup", mouseUpHandler);
    } else {
      window.removeEventListener("mousemove", mouseMoveHandler);
      window.removeEventListener("mouseup", mouseUpHandler);
    }
  }, [thumb.isDragging, mouseMoveHandler, mouseUpHandler]);

  const mouseMoveHandler = useCallback(
    ({ clientX }) => {
      const containerRect = container.getBoundingClientRect();
      const newRanges = [...ranges];

      const offsetX = clientX - containerRect.left - window.pageXOffset;
      const bgCursorX = offsetX - imagePosition;
      const position = (bgCursorX / width) * 100;
      const formattedPosition = formatNumber(position, 3);

      if (thumb.current === "start") {
        if (formattedPosition < minPosition) return;
        if (formattedPosition + 2 > rangeItem.end) return;

        newRanges.forEach((range) => {
          if (range.id === rangeItem.id) {
            range.start = formattedPosition;
          }
        });
      } else {
        if (formattedPosition > maxPosition) return;
        if (formattedPosition - 1.5 < rangeItem.start) return;

        newRanges.forEach((range) => {
          if (range.id === rangeItem.id) {
            range.end = formattedPosition;
          }
        });
      }

      setRanges(newRanges);
    },
    [thumb.start, thumb.current],
  );

  const mouseUpHandler = useCallback(() => {
    setThumb((state) => ({ ...state, isDragging: false }));
    updateEpisodes(ranges);
  }, []);

  const thumbContainerStyle = useMemo(
    () => ({
      right: `calc(${100 - rangeItem.end}% - 20px)`,
      left: `calc(${rangeItem.start}% - 35px)`,
    }),
    [rangeItem.end, rangeItem.start],
  );

  return (
    <div
      className={`${styles.spectrogram__thumb_container} ${
        styles[rangeItem.type]
      }`}
      style={thumbContainerStyle}
      id="range"
    >
      <div
        className={styles.spectrogram__thumb_left}
        onMouseDown={({ clientX }) => mouseDownHandler(clientX, "start")}
      />
      <div
        className={styles.spectrogram__thumb_right}
        onMouseDown={({ clientX }) => mouseDownHandler(clientX, "end")}
      />
    </div>
  );
};
