import React, { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";

import styles from "./info.module.scss";

export const RecordInfo = ({ data }) => {
  const [open, setOpen] = useState(false);

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        if (open) setOpen(false);
      }}
    >
      <div className={styles.info} onClick={() => setOpen(!open)}>
        {open && (
          <ul className={styles.info__data}>
            <li className={styles.info__item}>
              <div className={styles.info__item_title}>Framerate</div>
              <div className={styles.info__item_text}>{data.framerate}</div>
            </li>
            <li className={styles.info__item}>
              <div className={styles.info__item_title}>Treble visibility</div>
              <div className={styles.info__item_text}>{data.treble}</div>
            </li>
            <li className={styles.info__item}>
              <div className={styles.info__item_title}>Filters</div>
              <div className={styles.info__item_text}>{data.filters}</div>
            </li>
            <li className={styles.info__item}>
              <div className={styles.info__item_title}>Background noize</div>
              <div className={styles.info__item_text}>{data.bg_noize}</div>
            </li>
            <li className={styles.info__item}>
              <div className={styles.info__item_title}>SN ratio</div>
              <div className={styles.info__item_text}>{data.sn_ratio}</div>
            </li>
          </ul>
        )}
      </div>
    </OutsideClickHandler>
  );
};
