import { appService } from "../utils";

async function login(loginData) {
  let result;

  try {
    const response = await appService.post("/admin/login", loginData);
    result = response.data;
  } catch (error) {
    result = false;
  }

  return result;
}

async function refreshToken(refreshTokenId) {
  let result;

  try {
    const response = await appService.post("/token/refresh", {
      refreshTokenId,
    });
    result = response;
  } catch (error) {
    result = error.response;
  }
  return result;
}

async function logout(refreshTokenId) {
  let result;

  try {
    const response = await appService.post("/token/logout", { refreshTokenId });
    result = response;
  } catch (error) {
    result = false;
  }
  return result;
}

async function getDiagnosticById(id, filterStatus) {
  let result;

  let filterStr = "";
  filterStr =
    filterStatus && filterStatus === "pending"
      ? `?status=${filterStatus}`
      : filterStatus !== "pending"
      ? `?status[]=${filterStatus[0]}&status[]=${filterStatus[1]}`
      : "";

  try {
    const response = await appService.get(
      `/admin/processing/${id}/${filterStr}`
    );
    result = response.data;
  } catch (error) {
    result = false;
  }

  return result;
}

async function getDetailedDiagnostic(offset, sort, filters, gender, from, to) {
  let result;
  const sortStr = sort ? `&sort_by=${sort.type}(${sort.value})` : "";
  let filterStr = "";
  filters.map((filter) => (filterStr += `&status[]=${filter}`));
  if (!!gender) {
    filterStr += `&gender=${gender}`;
  }

  if (!!from) {
    filterStr += `&age[gte]=${from}`;
  }

  if (!!to) {
    filterStr += `&age[lte]=${to}`;
  }

  try {
    const response = await appService.get(
      `/admin/processing/?offset=${offset}&limit=30${sortStr}${filterStr}`
    );
    result = response.data;
  } catch (error) {
    result = false;
  }

  return result;
}

async function patchDiagnostic(id, data) {
  let result;

  try {
    const response = await appService.patch(`/admin/processing/${id}/`, data);
    result = response.status;
  } catch (error) {
    result = false;
  }

  return result;
}

async function patchDetails(id, data) {
  let result;

  try {
    const response = await appService.patch(
      `/admin/processing/${id}/details`,
      data
    );
    result = response.status;
  } catch (error) {
    result = false;
  }

  return result;
}

async function getDiagnosticCoughAudio(id) {
  let result;

  try {
    const response = await appService.get(
      `/admin/processing/${id}/cough_audio`,
      {
        responseType: "blob",
      }
    );

    if (response.headers["content-type"] === "audio/ogg") {
      const file = new File([response.data], "audio.ogg");
      const converted = await convertOgg(file);

      result = URL.createObjectURL(converted);
    } else {
      result = URL.createObjectURL(response.data);
    }
  } catch (error) {
    result = false;
  }

  return result;
}

async function convertOgg(file) {
  let result;

  const formData = new FormData();

  formData.append("file", file);

  try {
    const response = await appService.post("/convert", formData, {
      responseType: "blob",
    });

    result = new Blob([response.data], {
      type: "audio/wav",
    });
  } catch (error) {
    console.log(error);
  }

  return result;
}

async function markNoisy(id) {
  let result;

  try {
    const response = await appService.post(`/admin/processing/${id}/noisy`);
    result = response.status;
  } catch (error) {
    result = false;
  }

  return result;
}

async function getNavigationById(id, sort) {
  let result;
  const sortStr = sort ? `?sort_by=${sort.type}(${sort.value})` : "";

  try {
    const response = await appService.get(
      `/admin/marking/${id}/navigation/${sortStr}`
    );
    result = response.data;
  } catch (error) {
    result = false;
  }

  return result;
}

async function getMarking(offset, sort) {
  let result;
  const sortStr = sort ? `&sort_by=${sort.type}(${sort.value})` : "";

  try {
    const response = await appService.get(
      `/admin/marking/?offset=${offset}&limit=30${sortStr}`
    );
    result = response.data;
  } catch (error) {
    result = false;
  }

  return result;
}

async function getMarkingGeneralInfo(id) {
  let result;

  try {
    const response = await appService.get(`/admin/marking/${id}/general`);
    result = response.data;
  } catch (error) {
    result = false;
  }

  return result;
}

async function patchMarkingGeneralInfo(id, data) {
  let result;

  try {
    const response = await appService.patch(
      `/admin/marking/${id}/general`,
      data
    );
    result = response.data;
  } catch (error) {
    result = false;
  }

  return result;
}

async function getMarkingDetails(id, type) {
  let result;

  try {
    const response = await appService.get(
      `/admin/marking/${id}/${type}/detailed`
    );
    result = response.data;
  } catch (error) {
    result = false;
  }

  return result;
}

async function patchMarkingDetails(id, type, data) {
  let result;

  try {
    const response = await appService.patch(
      `/admin/marking/${id}/${type}/detailed`,
      data
    );
    result = response.status;
  } catch (error) {
    result = false;
  }

  return result;
}

async function getMarkingAudio(id, type) {
  let result;

  try {
    const response = await appService.get(
      `/admin/marking/${id}/${type}/audio`,
      { responseType: "blob" }
    );
    result = URL.createObjectURL(response.data);
  } catch (error) {
    result = false;
  }

  return result;
}

async function getMarkingSpectrogram(id, type) {
  let result;

  try {
    const response = await appService.get(
      `/admin/marking/${id}/${type}/spectrogram`,
      { responseType: "blob" }
    );
    result = response.data;
  } catch (error) {
    result = false;
  }

  return result;
}

async function getProcessingSpectrogram(id) {
  let result;

  try {
    const response = await appService.get(`admin/processing/${id}/spectrogram`,
    { responseType: "blob" });
    result = response.data;
  } catch (error) {
    result = false;
  }

  return result;
}

async function putAudioEpisodes(id, type, data) {
  let result;

  try {
    const response = await appService.put(
      `/admin/marking/${id}/${type}/episodes`,
      data
    );
    result = response.status;
  } catch (error) {
    result = false;
  }

  return result;
}

async function getProcessingStatistics () {
  let result;

  try {
    const response = await appService.get(`/admin/processing/statistics/diagnosis?status[]=success&status[]=noisy_audio`);
    result = response.data;
  } catch (error) {
    result = false;
  }

  return result;
}

export const actions = {
  login,
  logout,
  refreshToken,
  getDiagnosticById,
  getDetailedDiagnostic,
  patchDiagnostic,
  patchDetails,
  getDiagnosticCoughAudio,
  markNoisy,
  getNavigationById,
  getMarking,
  getMarkingGeneralInfo,
  patchMarkingGeneralInfo,
  getMarkingDetails,
  patchMarkingDetails,
  getMarkingAudio,
  getMarkingSpectrogram,
  getProcessingSpectrogram,
  putAudioEpisodes,
  getProcessingStatistics
};
