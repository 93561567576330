import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";

import {Table, TH, TR, TD, Checkbox} from "..";


export const StatusRecords = ({t,items,sort,sortStr,sortClickHandler,getMarking}) => {
  return (
    <div className="main-container">
    <Table className="data-markup-table">
      <TR className={"tr-data-markup"}>
        <TH
          className="markup-table_identifier"
          onClick={() => sortClickHandler("identifier")}
          sort={sort && sort.value === "identifier" ? sort.type : ""}
        >
          {t("identifier")}
        </TH>
        <TH
          className="markup-table_id"
          onClick={() => sortClickHandler("request_id")}
          sort={sort && sort.value === "request_id" ? sort.type : ""}
        >
          {t("Id")}
        </TH>
        <TH
          className="markup-table_cough"
          onClick={() => sortClickHandler("is_marked_doctor_cough")}
          sort={sort && sort.value === "is_marked_doctor_cough" ? sort.type : ""}
        >
          {t("cough_doc")}
        </TH>
        <TH
          className="markup-table_breathing"
          onClick={() => sortClickHandler("is_marked_doctor_breathing")}
          sort={sort && sort.value === "is_marked_doctor_breathing" ? sort.type : ""}
        >
          {t("breath_doc")}
        </TH>
        <TH
          className="markup-table_speech"
          onClick={() => sortClickHandler("is_marked_doctor_speech")}
          sort={sort && sort.value === "is_marked_doctor_speech" ? sort.type : ""}
        >
          {t("speech_doc")}
        </TH>
        <TH
          className="markup-table_cough"
          onClick={() => sortClickHandler("is_marked_scientist_cough")}
          sort={sort && sort.value === "is_marked_scientist_cough" ? sort.type : ""}
        >
          {t("cough_sci")}
        </TH>
        <TH
          className="markup-table_breathing"
          onClick={() => sortClickHandler("is_marked_scientist_breathing")}
          sort={sort && sort.value === "is_marked_scientist_breathing" ? sort.type : ""}
        >
          {t("breath_sci")}
        </TH>
        <TH 
          className="markup-table_speech"
          onClick={() => sortClickHandler("is_marked_scientist_speech")}
          sort={sort && sort.value === "is_marked_scientist_speech" ? sort.type : ""}
          >
          {t("speech_sci")}
        </TH>
      </TR>
      <InfiniteScroll
        dataLength={items.length}
        next={getMarking}
        hasMore={true}
        scrollThreshold="300px"
    >
        {items.map((item) => (
          <TR
            key={item.request_id + item.identifier}
            href={`/status/${item.request_id}`}
            as={`/status/${item.request_id}${sortStr}`}
          >
            <TD className="markup-table_identifier">{item.identifier}</TD>
            <TD className="markup-table_id">{item.request_id}</TD>
            <TD className="markup-table_cough">
              <Checkbox
                onChange={(e) => null}
                checked={item.is_marked_doctor_cough}
                value="cough_doc"
              ></Checkbox>
            </TD>
            <TD className="markup-table_breathing">
              <Checkbox
                onChange={(e) => null}
                checked={item.is_marked_doctor_breathing}
                value="breath_doc"
              ></Checkbox>
            </TD>
            <TD className="markup-table_speech">
              <Checkbox
                onChange={(e) => null}
                checked={item.is_marked_doctor_speech}
                value="speech_doc"
              ></Checkbox>
            </TD>
            <TD className="markup-table_cough">
              <Checkbox
                onChange={(e) => null}
                checked={item.is_marked_scientist_cough}
                value="cough_sci"
              ></Checkbox>
            </TD>
            <TD className="markup-table_breathing">
              <Checkbox
                onChange={(e) => null}
                checked={item.is_marked_scientist_breathing}
                value="breath_sci"
              ></Checkbox>
            </TD>
            <TD className={item.status} className="markup-table_speech">
              <Checkbox
                onChange={(e) => null}
                checked={item.is_marked_scientist_speech}
                value="speech_sci"
              ></Checkbox>
            </TD>
          </TR>
        ))}
      </InfiniteScroll>
    </Table>
  </div>
  );
};
