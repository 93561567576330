import React, { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";

import { getDate } from "../../utils";
import { Table, TH, TR, TD } from "../../components";

export const ParametrsRecords = ({
  t,
  items,
  sort,
  sortStr,
  sortClickHandler,
  getMarking,
}) => {
  let doctorDiagnosis = "";
  let audioScientist = "";

  return (
    <div className="main-container">
      <Table className="data-markup-table">
        <TR className="tr-data-markup">
          {/* <TH
          width="17%"
          align="center"
          onClick={() => sortClickHandler("identifier")}
          sort={sort && sort.value === "identifier" ? sort.type : ""}
          className={"data_markup"}
        >
          {t("identifier")}
        </TH> */}
          <TH
            onClick={() => sortClickHandler("request_id")}
            sort={sort && sort.value === "request_id" ? sort.type : ""}
            className={"data_markup markup-table_title"}
          >
            {t("title")}
          </TH>
          <TH
            className="markup-table_date"
            // onClick={() => sortClickHandler("date_created")}
            // sort={sort && sort.value === "date_created" ? sort.type : ""}
          >
            {t("date_created")}
          </TH>
          <TH
            className="markup-table_diagnosis"
            // onClick={() => sortClickHandler("doctor_status")}
            // sort={sort && sort.value === "doctor_status" ? sort.type : ""}
          >
            {t("diagnosis")}
          </TH>
          <TH
            className="markup-table_audio"
            // onClick={() => sortClickHandler("marking_status")}
            // sort={sort && sort.value === "marking_status" ? sort.type : ""}
          >
            {t("audio")}
          </TH>
          <TH
            className="markup-table_source"
            // onClick={() => sortClickHandler("data_source")}
            // sort={sort && sort.value === "data_source" ? sort.type : ""}
          >
            {t("data_source")}
          </TH>
          <TH
            className="markup-table_status"
            // onClick={() => sortClickHandler("covid_status")}
            // sort={sort && sort.value === "covid_status" ? sort.type : ""}
          >
            {t("covid_status")}
          </TH>
        </TR>
        <InfiniteScroll
          dataLength={items.length}
          next={getMarking}
          hasMore={true}
          scrollThreshold="300px"
        >
          {items.map((item) => {
            if (
              [
                item.is_marked_doctor_breathing,
                item.is_marked_doctor_cough,
                item.is_marked_doctor_speech,
              ].some((el) => el !== true)
            ) {
              doctorDiagnosis = "in_progress";
            }
            if (
              [
                item.is_marked_doctor_breathing,
                item.is_marked_doctor_cough,
                item.is_marked_doctor_speech,
              ].every((el) => el === true)
            ) {
              doctorDiagnosis = "done";
            }
            if (
              !item.is_marked_doctor_breathing &&
              !item.is_marked_doctor_cough &&
              !item.is_marked_doctor_speech
            ) {
              doctorDiagnosis = "new";
            }

            if (
              [
                item.is_marked_scientist_breathing,
                item.is_marked_scientist_cough,
                item.is_marked_scientist_speech,
              ].some((el) => el !== true)
            ) {
              audioScientist = "in_progress";
            }
            if (
              [
                item.is_marked_scientist_breathing,
                item.is_marked_scientist_cough,
                item.is_marked_scientist_speech,
              ].every((el) => el === true)
            ) {
              audioScientist = "done";
            }
            if (
              !item.is_marked_scientist_breathing &&
              !item.is_marked_scientist_cough &&
              !item.is_marked_scientist_speech
            ) {
              audioScientist = "new";
            }

            return (
              <TR
                key={item.request_id + item.identifier}
                href={`/record/${item.request_id}`}
                as={`/record/${item.request_id}${sortStr}`}
              >
                {/* <TD width="17%" align="center">{item.identifier}</TD> */}
                <TD className="markup-table_title">{item.request_id}</TD>
                <TD className="markup-table_date">
                  {getDate(item.date_created, t("at"))}
                </TD>
                <TD className="markup-table_diagnosis">{doctorDiagnosis}</TD>
                <TD className="markup-table_audio">{audioScientist}</TD>
                <TD className="markup-table_source">{item.data_source}</TD>
                <TD className="markup-table_status">{item.covid_status}</TD>
              </TR>
            );
          })}
        </InfiniteScroll>
      </Table>
    </div>
  );
};
