import React from "react";

import styles from "./checkbox.module.scss";

export const Checkbox = ({ value, onChange, checked, children }) => {
  return (
    <label className={styles.checkbox}>
      <input
        type="checkbox"
        onChange={(e) => onChange(e)}
        value={value}
        checked={checked}
      />
      <span className={styles.checkbox__checkmark} />
      <span className={styles.checkbox__text}>{children}</span>
    </label>
  );
};
