import React, { useState, useEffect } from "react";

import styles from "./representative.module.scss";
import { Radio } from "../../components";
import { useTranslation } from "react-i18next";

export const RepresentativeComponent = ({
  representativeness,
  representative,
  setRepresentative,
}) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(null);

  useEffect(() => {
    if (representative === true) {
      setValue("yes");
    }

    if (representative === false) {
      setValue("no");
    }

    if (representative === null) {
      setValue(null);
    }
  }, [representative]);

  return (
    <div className={styles.representative}>
      <div className={styles.representative__title}>
        {t(representativeness)}
      </div>
      <div className={styles.representative__selectors}>
        <Radio
          value="yes"
          onChange={() => {
            setRepresentative(true);
          }}
          checked={value}
        >
          {t("representative")}
        </Radio>
        <Radio
          value="no"
          onChange={() => {
            setRepresentative(false);
          }}
          checked={value}
        >
          {t("not_representative")}
        </Radio>
      </div>
    </div>
  );
};

export const Representative = RepresentativeComponent;
