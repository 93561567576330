import React from "react";

import { Search} from "../../components";
import styles from "./headerMarkup.module.scss";

export const HeaderMarkup = () => {
  return (
    <div className={styles.header}>
      
      <div className={`${styles.header__container}`}>
      <Search className={"data-markup"}/>
      </div>
    </div>
  );
};
