import React from "react";
import { Link } from "react-router-dom";

import styles from "./table.module.scss";

export const TH = ({ children, className, width, align, onClick, sort }) => {
  const style = {
    width: width && width,
    cursor: sort !== undefined ? "pointer" : "initial",
  };

  return (
    <div
      className={`${styles.table__head} ${className ? className : ""} ${sort ? styles[sort] : styles["show-sort"]}`}
      style={style}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export const TD = ({ children, className, width, align }) => {
  const style = {
    width: width && width,
  };
  return (
    <div
      className={`${styles.table__cell} ${className ? className : ""}`}
      style={style}
    >
      {children}
    </div>
  );
};

export const TR = ({ children, className, href, as }) =>
  href ? (
    <Link
      to={href}
      as={as}
      className={`${styles.table__row} ${className ? className : ""}`}
      onClick={(e) => {
        if (e.target.closest(".no-redirect")) {
          e.preventDefault();
        }
      }}
    >
      {children}
    </Link>
  ) : (
    <div
      className={`${styles.table__row} ${className ? className : ""} ${
        className ? styles[className] : ""
      }`}
    >
      {children}
    </div>
  );

export const Table = ({ children, className, column }) => {
  return (
    <div
      className={`${styles.table} ${className ? className : ""} ${
        className ? styles[className] : ""
      } ${column ? styles.table__column : ""}`}
    >
      {children}
    </div>
  );
};
