export const log = (message) => {
  if (process.env.NODE_ENV === "development") {
    console.log(message);
  }
};

export const getDate = (date, at, role) => {
  if (date === undefined) 
	return "";
  let offset = 4 + new Date(date).getTimezoneOffset() / 60; //смещение времени Дубая
  const dateViewer = new Date(date).toISOString().replace("Z", `-0${offset}:00`);
  const dateDubai = new Date(dateViewer);

  const formattedDate = role === "viewer" ? dateDubai : new Date(date);

  const DD =
    formattedDate.getDate() > 9
      ? formattedDate.getDate()
      : `0${formattedDate.getDate()}`;
  const MM =
    formattedDate.getMonth() > 8
      ? formattedDate.getMonth() + 1
      : `0${formattedDate.getMonth() + 1}`;
  const YYYY = formattedDate.getFullYear();
  const HH =
    formattedDate.getHours() > 8
      ? formattedDate.getHours()
      : `0${formattedDate.getHours()}`;
  const _mm =
    formattedDate.getMinutes() > 8
      ? formattedDate.getMinutes()
      : `0${formattedDate.getMinutes()}`;

  return `${DD}.${MM}.${YYYY} ${at} ${HH}:${_mm}`;
};

export const declOfNum = (n) => {
  const titles = ["год", "года", "лет"];
  const currentTitle =
    titles[
      n % 10 === 1 && n % 100 !== 11
        ? 0
        : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20)
        ? 1
        : 2
    ];

  return `${n} ${currentTitle}`;
};

export const formatNumber = (number, fixed) => Number(number.toFixed(fixed));
