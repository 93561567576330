export const diagnosisChoices = [
  { text: "healthy", status: "healthy" },
  { text: "at_risk", status: "at_risk" },
  { text: "COVID-19", status: "covid_19" },
];

export const productivityChoices = [
  { text: "productive", status: "productive" },
  { text: "wet_productive_small", status: "wet_productive_small" },
  { text: "dry_productive_small", status: "dry_productive_small" },
  { text: "unproductive", status: "unproductive" },
];

export const intensityChoices = [
  { text: "paroxysmal", status: "paroxysmal" },
  { text: "paroxysmal_hacking", status: "paroxysmal_hacking" },
  { text: "not_paroxysmal", status: "not_paroxysmal" },
];

export const genderChoices = [
  { text: "male", status: "male" },
  { text: "female", status: "female" },
];

export const smokeChoices = [
  { text: "yes", status: true },
  { text: "no", status: false },
];

export const statuses = [
  { text: "done", status: "done" },
  { text: "in_process", status: "in_process" },
  { text: "not_ready", status: "not_ready" },
];

export const difficultyType = [
  { text: "heavy", status: "heavy" },
  { text: "normal", status: "normal" },
];

export const durationType = [
  { text: "enlogated", status: "enlogated" },
  { text: "short", status: "short" },
  { text: "normal", status: "normal" },
];

export const depthType = [
  { text: "shallow", status: "shallow" },
  { text: "deep", status: "deep" },
];
