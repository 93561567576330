import React, { Suspense } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { AuthProvider } from "./utils";
import { Preloader } from "./components";

const LoginPage = React.lazy(() => import("./pages/LoginPage/index"));
const ProcessingRecordsPage = React.lazy(() =>
  import("./pages/ProcessingRecordsPage/index"),
);
const ItemPage = React.lazy(() => import("./pages/ItemPage/index"));
const DataMarkupPage = React.lazy(() => import("./pages/DataMarkupPage/index"));
const RecordPage = React.lazy(() => import("./pages/record/RecordPage/index"));
const reportPage = React.lazy(() => import("./pages/reportPage/index"));

export default function App() {
  return (
    <Router>
      <Suspense fallback={<Preloader />}>
        <AuthProvider>
          <Switch>
            <Route path="/login" exact component={LoginPage} />
            <Route path="/" exact component={ProcessingRecordsPage} />
            <Route path="/data_markup" exact component={DataMarkupPage} />
            <Route path="/item/:id" exact component={ItemPage} />
            <Route path="/record/:id" exact component={RecordPage} />
            <Route path="/status/:id" exact component={RecordPage} />
            <Route path="/report/:id" exact component={reportPage} />
          </Switch>
        </AuthProvider>
      </Suspense>
    </Router>
  );
}
