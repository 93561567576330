import React from "react";

import styles from "./radio.module.scss";

export const Radio = ({ value, onChange, checked, children }) => {
  return (
    <label className={styles.radio}>
      <input
        type="radio"
        value={value}
        checked={value === checked}
        onChange={(e) => onChange(e)}
        className={styles.radio__input}
      />
      <span className={styles.radio__checkmark} />
      <span className={styles.radio__text}>{children}</span>
    </label>
  );
};
