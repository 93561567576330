import axios from "axios";
import { actions } from "../actions";

import { ls } from "../utils";

const API_URL = "https://store.acoustery.com/v1.2";
const DEV_URL = "https://store.acoustery.com/v1.2";
// const DEV_URL = "https://stage.store.acoustery.com/v1.1";

export const appService = axios.create({
  baseURL: process.env.NODE_ENV === "development" ? DEV_URL 

	: process.env.REACT_APP_STAGE === "stage" ? DEV_URL

	: API_URL,
  responseType: "json",
});

appService.interceptors.response.use(
  (response) => {
    if(response.config.url==="/token/refresh" && response.status===200){
      ls.set("token", response.data.token);
      appService.defaults.headers.common["Authorization"] = response.data.token;
      ls.set("refreshTokenId", response.data.refreshTokenId);
      window.location.reload();
    }
    return response;
  },
  async (error) => {

    if (error.response.status === 401) {
      actions.refreshToken(ls.get("refreshTokenId")).then(response => {
        if(response.status === 401) {
          ls.remove("token");
          delete appService.defaults.headers.common["Authorization"];
          window.location.reload();
        }
      });
    }

    return Promise.reject(error);
  },
);
