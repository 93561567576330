import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import styles from "./selectedBtn.module.scss";

const SelectedBtnComponent = ({className,item,filterStr,listChecked}) => {

  const { t } = useTranslation(); 
  const list = listChecked==="list" ? "selected" : "";
  const carousel = listChecked==="carousel" ? "selected" : "";

  return (
    <div className={`${styles.wrapperBtn} ${styles[className ? className : ""]}`}>
      <Link to={`/${filterStr?filterStr:"/"}`}>
        <button 
          className={styles.btn + " " + styles.parametrsBtn + " " + styles[list]}
        >
          {t("list")}
        </button>
      </Link>
      <Link
        to={item ? `/item/${item?item.request_id:""}${filterStr?filterStr:""}`: '/'} //  <--- заглушка
      >
        <button
          className={styles.btn + " " + styles.markupBtn + " " + styles[carousel]}
        >
          {t("carousel")}
        </button>
      </Link>
    </div>
  );
};

export const SelectedBtn = SelectedBtnComponent;
