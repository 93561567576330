import React from "react";
import styles from "./reportHeader.module.scss";
import { useTranslation } from "react-i18next";
import { ls } from "../../utils";
import i18n from "../../utils/_i18n";

export const ReportHeader = () => {
  const { t } = useTranslation();
  const [language, setLanguage] = React.useState(ls.get("language"));
  const [langMenu, setLangMenu] = React.useState(false);

  const showLangMenu = () => {
    if (langMenu) {
      setLangMenu(false);
    } else {
      setLangMenu(true);
    }
  };


  const changeLanguage = (lang) => {
    ls.set("language", lang);
    setLanguage(lang);
    setLangMenu(false);
  };

  React.useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [language]);
  return (
    <>
      <div className={styles.container}>
          <div className={styles.reportHeader_logo}></div>
          <div className={styles.reportHeader_line}>
            <div className={styles.locales_flex_wrapper}>
              <div className={styles.locales}>
                <div
                  className={`${styles.en_locales} ${
                    langMenu ? styles["active_locales"] : ""
                  }`}
                  onClick={() => changeLanguage(language !== "en" ? "en" : "ru")}
                >
                  {language !== "en" ? "En" : "Ru"}
                </div>
                <div className={styles.ru_locales} onClick={showLangMenu}>
                  {language !== "en" ? "Ru" : "En"}
                </div>
                <div
                  className={`${styles.move_element} ${
                    langMenu ? styles["active_move_element"] : ""
                  }`}
                ></div>
              </div>
            </div>
          </div>
          <h2 className={styles.reportHeader_header_title}> {t("test-title")}</h2>
      </div>
    </>
  );
};