import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { useHistory } from "react-router-dom";

import { SpectrogramRange, SpectrogramPlayer } from "../../components";
import { formatNumber } from "../../utils";
import { kHz, dB } from "../../constants";
import styles from "./spectrogram.module.scss";

const zoom = 0.1;
const maxZoom = 2.5;
const size = 954;

export const Spectrogram = ({
  audio,
  image,
  episodes,
  updateEpisodes,
  duration,
  setDuration,
}) => {
  const history = useHistory();
  const breath = history.location.pathname.includes("breath");

  const [audioElement, setAudioElement] = useState(null);
  const [progress, setProgress] = useState(0);
  const [width, setWidth] = useState(size);
  const [scale, setScale] = useState(1);
  const [state, setState] = useState({
    drag: false,
    isDragging: false,
    start: 0,
    translation: 0,
    translationStart: 0,
  });
  const [ranges, setRanges] = useState([]);
  const [player, setPlayer] = useState({
    isDragging: false,
    start: 0,
    translation: 0,
    translationStart: 0,
  });

  const containerEl = useRef(null);
  const imageEl = useRef(null);

  useEffect(() => {
    if (duration && !ranges.length) {
      const formattedEpisodes = [];

      episodes.forEach((episode) => {
        const start = (episode.start / duration) * 100;
        const end = (episode.end / duration) * 100;

        formattedEpisodes.push({
          ...episode,
          start: formatNumber(start, 2),
          end: formatNumber(end, 2),
        });
      });

      setRanges(formattedEpisodes);
    }
  }, [duration]);

  useEffect(() => {
    if (imageEl) {
      const img = imageEl.current;

      const cancelWheel = (event) => event.preventDefault();

      img.addEventListener("wheel", cancelWheel);

      return () => {
        img.removeEventListener("wheel", cancelWheel);
      };
    }
  }, [imageEl]);

  const wheelHandler = (e) => {
    const container = containerEl.current;
    const containerRect = container.getBoundingClientRect();

    const img = imageEl.current;
    const imgRect = img.getBoundingClientRect();

    let imgWidth = imgRect.width;
    let imgPosX = state.translation;

    let deltaY = 0;

    if (e.deltaY) {
      deltaY = e.deltaY;
    } else if (e.wheelDelta) {
      deltaY = -e.wheelDelta;
    }

    if (deltaY > 0 && scale <= 1) {
      return;
    }

    if (deltaY < 0 && scale >= maxZoom) {
      return;
    }

    const offsetX = e.pageX - containerRect.left - window.pageXOffset;
    const bgCursorX = offsetX - imgPosX;
    const bgRatioX = bgCursorX / imgWidth;

    if (deltaY < 0) {
      setScale(scale + zoom);
      imgWidth = size * (scale + zoom);
      setWidth(imgWidth);
    } else {
      setScale(scale - zoom);
      imgWidth = size * (scale - zoom);
      setWidth(imgWidth);
    }

    imgPosX = offsetX - imgWidth * bgRatioX;

    if (imgPosX > 0) {
      imgPosX = 0;
    } else if (imgPosX < size - imgWidth) {
      imgPosX = size - imgWidth;
    }

    setState((state) => ({ ...state, translation: imgPosX }));
  };

  const mouseUpHandler = useCallback(() => {
    setState((state) => ({ ...state, isDragging: false }));
  }, []);

  const clickHandler = ({ clientX, target }) => {
    if (target.id === "delete" || target.id === "type") return;

    if (!state.drag) {
      const container = containerEl.current;
      const containerRect = container.getBoundingClientRect();

      const offsetX = clientX - containerRect.left - window.pageXOffset;
      const bgCursorX = offsetX - state.translation;
      const newProgress = bgCursorX / width;

      setProgress(newProgress);
    } else {
      setState((state) => ({ ...state, drag: false }));
      return;
    }
  };

  const mouseDownHandler = useCallback(({ clientX, target }) => {
    if (target.id === "playerThumb") return;

    setState((state) => ({
      ...state,
      isDragging: true,
      start: clientX,
      translationStart: state.translation,
    }));
  }, []);

  const mouseMoveHandler = useCallback(
    ({ clientX }) => {
      const translation = clientX - state.start;
      const newMargin = state.translationStart + translation;
      const maxMargin = size * (scale - 1) * -1;

      if (newMargin >= 0) {
        setState((state) => ({
          ...state,
          translation: 0,
        }));
        return;
      }

      if (newMargin <= maxMargin) {
        setState((state) => ({
          ...state,
          translation: maxMargin,
        }));
        return;
      }

      setState((state) => ({ ...state, drag: true, translation: newMargin }));
    },
    [state.start, state.translationStart],
  );

  useEffect(() => {
    if (state.isDragging) {
      if (scale === 1) {
        setState({
          isDragging: false,
          start: 0,
          translation: 0,
          translationStart: 0,
        });
        return;
      }

      window.addEventListener("mousemove", mouseMoveHandler);
      window.addEventListener("mouseup", mouseUpHandler);
    } else {
      window.removeEventListener("mousemove", mouseMoveHandler);
      window.removeEventListener("mouseup", mouseUpHandler);
    }
  }, [state.isDragging, mouseMoveHandler, mouseUpHandler]);

  const doubleClickHandler = (e) => {
    if (!e.target.closest("#range")) {
      const container = containerEl.current;
      const containerRect = container.getBoundingClientRect();

      const offsetX = e.clientX - containerRect.left - window.pageXOffset;
      const bgCursorX = offsetX - state.translation;

      const positionStart = bgCursorX / width;
      const start = positionStart * 100;
      const end = (positionStart + 0.02) * 100;

      const newRanges = [...ranges];
      newRanges.push({
        id: newRanges.length ? newRanges[newRanges.length - 1].id + 1 : 0,
        type: breath ? "breathing_inhale" : "other",
        start,
        end,
      });
      setRanges(newRanges);
      updateEpisodes(newRanges);
    }
  };

  const playerMouseDownHandler = useCallback(
    ({ clientX }) => {
      audioElement.pause();

      setPlayer((state) => ({
        ...state,
        isDragging: true,
        start: clientX,
        translationStart: state.translation,
      }));
    },
    [audioElement],
  );

  const playerMouseMoveHandler = useCallback(
    ({ clientX }) => {
      const container = containerEl.current;
      const containerRect = container.getBoundingClientRect();

      const offsetX = clientX - containerRect.left - window.pageXOffset;
      const bgCursorX = offsetX - state.translation;
      const newProgress = bgCursorX / width;

      if (newProgress < 0) {
        setProgress(0);
        return;
      }

      if (newProgress > 1) {
        setProgress(1);
        return;
      }

      setProgress(newProgress);
    },
    [player.start, player.translationStart],
  );

  const playerMouseUpHandler = useCallback(() => {
    setPlayer((state) => ({ ...state, isDragging: false }));
  }, []);

  useEffect(() => {
    if (player.isDragging) {
      window.addEventListener("mousemove", playerMouseMoveHandler);
      window.addEventListener("mouseup", playerMouseUpHandler);
    } else {
      window.removeEventListener("mousemove", playerMouseMoveHandler);
      window.removeEventListener("mouseup", playerMouseUpHandler);
    }
  }, [player.isDragging, playerMouseMoveHandler, playerMouseUpHandler]);

  const deleteRange = (id) => {
    let newRanges = [...ranges];
    newRanges = newRanges.filter((range) => range.id !== id);
    setRanges(newRanges);
    updateEpisodes(newRanges);
  };

  const changeType = (id) => {
    let newRanges = [...ranges];
    newRanges.forEach((range) => {
      if (range.id === id) {
        range.type =
          range.type === "breathing_inhale"
            ? "breathing_exhale"
            : "breathing_inhale";
      }
    });

    setRanges(newRanges);
    updateEpisodes(newRanges);
  };

  const addCoords = () => {
    const coords = [];
    const ticksSize = formatNumber(duration, 0);
    const margin = (duration - ticksSize) / duration;
    const formattedMargin = `${formatNumber(margin, 3) * 100}%`;

    for (let i = 0; i <= ticksSize; i++) {
      coords.push(
        <div
          key={i}
          className={styles.spectrogram__coord}
          style={i === ticksSize ? { marginRight: formattedMargin } : {}}
        >
          <div>{i}</div>
        </div>,
      );
    }

    return coords;
  };

  const getCursor = useMemo(() => {
    if (scale === 1) {
      return "default";
    } else {
      if (state.isDragging) {
        return "grabbing";
      } else {
        return "grab";
      }
    }
  }, [scale, state.isDragging]);

  return (
    <div className={styles.spectrogram}>
      <div className={styles.spectrogram__chart}>
        <div className={styles.spectrogram__axis_y_left}>
          {kHz.map((item) => (
            <div key={item} className={styles.spectrogram__coord}>
              <div>{item}</div>
            </div>
          ))}
        </div>
        <div className={styles.spectrogram__container}>
          <div
            className={styles.spectrogram__image_container}
            ref={containerEl}
          >
            <div
              className={styles.spectrogram__image}
              style={{
                backgroundImage: `url(${image})`,
                marginLeft: `${state.translation}px`,
                width: `${width}px`,
                cursor: getCursor,
              }}
              ref={imageEl}
              onWheel={(e) => wheelHandler(e)}
              onMouseDown={(e) => mouseDownHandler(e)}
              onClick={(e) => clickHandler(e)}
            >
              <div
                className={styles.spectrogram__player_progress}
                style={{ width: `${progress * 100}%` }}
              >
                <div
                  className={styles.spectrogram__player_thumb}
                  onMouseDown={(e) => playerMouseDownHandler(e)}
                  id="playerThumb"
                />
              </div>
              {ranges.map((range) => (
                <div
                  key={range.id}
                  className={styles.spectrogram__range}
                  style={
                    range.start
                      ? {
                          left: `${range.start}%`,
                          right: `calc(100% - ${range.end}%)`,
                        }
                      : { display: "none" }
                  }
                >
                  <div
                    className={styles.spectrogram__range_delete}
                    id="delete"
                    onClick={() => deleteRange(range.id)}
                  />
                  {breath && (
                    <div
                      className={`${styles.spectrogram__range_type} ${
                        styles[range.type]
                      }`}
                      id="type"
                      onClick={() => changeType(range.id)}
                    >
                      {range.type === "breathing_exhale" ? "ex" : "in"}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
          {duration && (
            <div className={styles.spectrogram__axis_x_container}>
              <div
                className={styles.spectrogram__axis_x}
                style={{ width: `${width + 10}px` }}
              >
                <div className={styles.spectrogram__axis_x_range} />
                <div
                  className={styles.spectrogram__axis_x_coords}
                  style={{ marginLeft: `${state.translation}px` }}
                >
                  <div
                    className={styles.spectrogram__axis_x_ranges}
                    onDoubleClick={(e) => doubleClickHandler(e)}
                  >
                    {ranges.map((range) => (
                      <SpectrogramRange
                        key={range.id}
                        rangeItem={range}
                        width={width}
                        setRanges={setRanges}
                        updateEpisodes={updateEpisodes}
                        ranges={ranges}
                        container={containerEl.current}
                        imagePosition={state.translation}
                      />
                    ))}
                  </div>

                  {addCoords()}
                </div>
              </div>
            </div>
          )}
        </div>
        <div className={styles.spectrogram__db}>
          <div className={styles.spectrogram__db_text}>dB</div>
        </div>
        <div className={styles.spectrogram__axis_y_right}>
          {dB.map((item) => (
            <div key={item} className={styles.spectrogram__coord}>
              <div>{item}</div>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.spectrogram__player}>
        {audio && (
          <SpectrogramPlayer
            audio={audio}
            progress={progress}
            setProgress={setProgress}
            audioElement={audioElement}
            setAudioElement={setAudioElement}
            duration={duration}
            setDuration={setDuration}
          />
        )}
      </div>
    </div>
  );
};
