import React, { useState, useEffect } from "react";

import styles from "./player.module.scss";
import { actions } from "../../actions";

export const PlayerMarking = ({ type, id, audio}) => {

  const [audioElement, setAudioElement] = useState(null);
  const [play, setPlay] = useState(false);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState("0%");


  const clickPlay = () => {
    if (!play) {
      setPlay(true);
      audioElement.play();
    } else {
      setPlay(false);
      audioElement.pause();
    }
  };

  const progressHandler = () => {
    setProgress(`${(audioElement.currentTime / audioElement.duration) * 100}%`);
  };

  useEffect(() => {
    if (audio) {
      const audioEl = new Audio(audio);
      setAudioElement(audioEl);
    }
  }, [audio]);

  useEffect(() => {
    if (audioElement) {
      audioElement.addEventListener("timeupdate", progressHandler);

      return () => {
        audioElement.pause();

        audioElement.removeEventListener("timeupdate", progressHandler);
      };
    }
    // eslint-disable-next-line
  }, [audioElement]);

  useEffect(() => {
    if (audioElement) {
      audioElement.pause();
      audioElement.currentTime = 0;
      audioElement.removeEventListener("timeupdate", progressHandler);

      setPlay(false);
    }
  }, [id]);

  return (
    <div className={`${styles.player} ${styles[type]}`}>
      <div className={styles.player__container}>
        {audio ? (
          <button
            className={`${styles.player__btn} ${
              play ? styles.pause : styles.play
            }`}
            onClick={clickPlay}
          />
        ) : (
          <button
            className={`${styles.player__btn} ${
              loading ? styles.loading : styles.download
            }`}
            disabled={loading}
          />
        )}
        {type === "full" && (
          <button
            className={`${styles.player__btn} ${styles.stop}`}
            onClick={() => {
              audioElement.pause();
              audioElement.currentTime = 0;
              setPlay(false);
            }}
          />
        )}

        <div className={styles.player__progress}>
          <div className={styles.player__progress_container}>
            <div
              className={styles.player__progress_bar}
              style={{ width: progress }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
