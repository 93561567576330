import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import styles from "./headerPacient.module.scss";
import { ls } from "../../utils";


export const HeaderPacient = ({
  info,
  setSave,
  prev,
  next,
  dirty,
  setDirty,
  routerPrev,
  routerNext,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  function ageToStr(age) {
    let txt;
    let count = age % 100;
    if (count >= 5 && count <= 20) {
      txt = "лет";
    } else {
      count = count % 10;
      if (count == 1) {
        txt = "год";
      } else if (count >= 2 && count <= 4) {
        txt = "года";
      } else {
        txt = "лет";
      }
    }
    if(ls.get("language")==="en") {
      return age + " years old"
    }
    return age + " " + txt;
  }

  const goPrev = () => {
    history.push(`${routerPrev}`);
  }

  const goNext = () => {
    history.push(`${routerNext}`);
  }

  const dataSave = () => {
    setSave(true)
    setDirty(false)
  }

  return (
    <div className={styles.header_wrapper}>
      <div className={styles.header}>
        <div className={styles.pacient_info}>
          <h2>ID {info ? info.request_id : "—"}</h2>
          <div className={styles.wrapper_pacient}> 
            <div className={styles.pacient}>
              <span>{t("patient")}:</span>
              <br />
              {info && info.gender + "," ? info.gender ? t("male") : t("female") : ""}{" "}
              {info && info.age ? ageToStr(info.age) + "," : ""}{" "}
              {info && info.is_smoking ? info.is_smoking ? t("smokes") : t("not smokes"): ""}
            </div>
            <div className={styles.record}>
              <span>{t("record")}:</span>
              <br />
              {info && info.data_source ? info.data_source ? t("data_source") : t("") + "," : ""}{" "}
              {info && info.full_diagnosis ? info.full_diagnosis + "," : ""}{" "}
              {info && info.covid19_symptomatic_type
                ? t(info.covid19_symptomatic_type)
                : ""}
            </div>
          </div>
        </div>

        <div className={styles.buttons}>
          {
            prev === null ? (
              <button className={styles.disabled}>
                {t("prev")}
              </button>
            ) : (
              <button className={styles.back} onClick={goPrev}>
                {t("prev")}
              </button>
            )
          }
          {!dirty ? (
            <button className={styles.disabled} disabled={!dirty}>
            {t("save")}
            </button>
          ) : (
            <button className={styles.back} onClick={dataSave}>
            {t("save")}
            </button>
          )}
          {
            next === null ? (
              <button className={styles.disabled}>
                {t("next")}
              </button>
            ) : (
              <button className={styles.back} onClick={goNext}>
                {t("next")}
              </button>
            )
          }
        </div>
      </div>
    </div>
  );
};
