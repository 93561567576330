import React from "react";

import styles from "./preloader.module.scss";

export const Preloader = () => {

  return (
    <div className={styles.preloader}>
      <div className={styles.lds_roller}>
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
};
